@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@charset "UTF-8";
.App {
  text-align: center; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ddd; }

.App-link {
  color: #2515ff; }

.logo {
  cursor: pointer; }

.video-segment {
  cursor: pointer; }

.video-segment:hover {
  background-color: whitesmoke; }

body {
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  font-family: 'Lato', sans-serif;
  text-rendering: optimizelegibility;
  overflow-x: hidden; }

body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff; }

body {
  font-family: 'Lato', sans-serif; }

@media (max-height: 512px) {
  body {
    padding-top: 0px; } }

.AppFooter .footer {
  font-size: 12px;
  padding: 30px 0;
  color: #26354a;
  background: #dcd6cc;
  border-top: 1px solid #d6d0c7; }

@media (min-width: 992px) {
  .AppFooter .container {
    width: 970px; } }

@media (min-width: 768px) and (min-height: 768px) {
  .AppFooter .container {
    width: 750px; } }

.AppFooter .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.AppFooter .text-center {
  text-align: center; }

/* layout */
.layout-content {
  padding-right: 2rem;
  padding-left: 2rem; }

@media (min-width: 1024px) {
  .layout-content {
    padding-right: 40px;
    padding-left: 40px; } }

@media (min-width: 1200px) {
  .layout-content {
    padding-right: 150px;
    padding-left: 150px; } }

@media (min-width: 1400px) {
  .layout-content {
    padding-right: 250px;
    padding-left: 250px; } }

.video-item {
  color: black;
  cursor: pointer; }

.video-item.item img {
  max-width: 200px;
  max-height: 250px; }
  .video-item.item img .stacked {
    display: inline; }

.segment-field[data-disabled] {
  cursor: not-allowed; }

.segment-field[disabled] {
  cursor: not-allowed; }

.segment-field.disabled {
  cursor: not-allowed; }

.segment-field .disabled {
  cursor: not-allowed; }

.segment-time-field {
  width: 90px;
  text-align: center;
  height: 35px;
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 16px !important; }

.segment-time-field[disabled] {
  cursor: not-allowed; }

.noUi-pips {
  height: 40px; }

.noUi-target {
  margin-top: 5px !important; }

.noUi-handle {
  background: #aaa; }

.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  bottom: -130%; }

.videodesc {
  text-align: left;
  margin: 20px;
  background-color: #eee;
  padding: 40px;
  border-width: 2px;
  border-color: lightgrey;
  border-style: solid;
  border-radius: 5px;
  white-space: pre-line;
  font-size: 12pt; }

.ui.segment.search-bar {
  background-color: #eee; }

.search-bar.ui.segment {
  padding: 10px;
  box-shadow: none;
  border-width: 0px; }

@media (max-width: 1024px) {
  .search-bar.ui.segment {
    margin-left: 100px;
    margin-right: 100px; } }

@media (max-width: 768px) {
  .search-bar.ui.segment {
    margin-left: 10px;
    margin-right: 10px; } }

.headerBreak {
  margin-top: -15px;
  background-color: #45535e;
  height: 20px;
  width: 100%; }

.vidTitle {
  text-align: left;
  background-color: #eee;
  padding: 20px;
  border-width: 1px;
  border-color: lightgrey;
  border-style: solid;
  border-radius: 3px;
  white-space: pre-line;
  font-size: 16pt;
  color: #666;
  margin-left: auto;
  margin-right: auto; }

.vidHeader {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 5px;
  position: relative; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  margin-bottom: 100px; }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* react-autosuggestions */
.react-autosuggest__container {
  position: relative;
  width: 100%; }

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  text-align: left; }

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd; }

.image-header {
  position: absolute;
  top: 60%;
  padding-left: 350px;
  transform: translate(-50%, -50%);
  color: black;
  /* or any color that contrasts with your image */
  font-size: 40px;
  /* or any size you prefer */
  font-weight: 800;
  text-align: center; }

.AppHeader {
  text-rendering: optimizelegibility;
  height: 82px;
  border-bottom: none;
  display: flex; }
  .AppHeader .no-header-border #site-header {
    border-bottom: none; }
  .AppHeader #site-header {
    border-color: #333333; }
  .AppHeader #site-header {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    z-index: 100; }
  .AppHeader article,
  .AppHeader aside,
  .AppHeader details,
  .AppHeader figcaption,
  .AppHeader figure,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader main,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader section,
  .AppHeader summary {
    display: block; }
  .AppHeader article,
  .AppHeader aside,
  .AppHeader details,
  .AppHeader figcaption,
  .AppHeader figure,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader main,
  .AppHeader nav,
  .AppHeader section {
    display: block; }
  .AppHeader article,
  .AppHeader aside,
  .AppHeader details,
  .AppHeader figcaption,
  .AppHeader figure,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader section {
    display: block; }
  .AppHeader ﻿html,
  .AppHeader body,
  .AppHeader div,
  .AppHeader span,
  .AppHeader applet,
  .AppHeader object,
  .AppHeader iframe,
  .AppHeader h1,
  .AppHeader h2,
  .AppHeader h3,
  .AppHeader h4,
  .AppHeader h5,
  .AppHeader h6,
  .AppHeader p,
  .AppHeader blockquote,
  .AppHeader pre,
  .AppHeader a,
  .AppHeader abbr,
  .AppHeader acronym,
  .AppHeader address,
  .AppHeader big,
  .AppHeader cite,
  .AppHeader code,
  .AppHeader del,
  .AppHeader dfn,
  .AppHeader em,
  .AppHeader img,
  .AppHeader ins,
  .AppHeader kbd,
  .AppHeader q,
  .AppHeader s,
  .AppHeader samp,
  .AppHeader small,
  .AppHeader strike,
  .AppHeader strong,
  .AppHeader sub,
  .AppHeader sup,
  .AppHeader tt,
  .AppHeader var,
  .AppHeader b,
  .AppHeader u,
  .AppHeader i,
  .AppHeader center,
  .AppHeader dl,
  .AppHeader dt,
  .AppHeader dd,
  .AppHeader ol,
  .AppHeader ul,
  .AppHeader li,
  .AppHeader fieldset,
  .AppHeader form,
  .AppHeader label,
  .AppHeader legend,
  .AppHeader table,
  .AppHeader caption,
  .AppHeader tbody,
  .AppHeader tfoot,
  .AppHeader thead,
  .AppHeader tr,
  .AppHeader th,
  .AppHeader td,
  .AppHeader article,
  .AppHeader aside,
  .AppHeader canvas,
  .AppHeader details,
  .AppHeader embed,
  .AppHeader figure,
  .AppHeader figcaption,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader output,
  .AppHeader ruby,
  .AppHeader section,
  .AppHeader summary,
  .AppHeader time,
  .AppHeader mark,
  .AppHeader audio,
  .AppHeader video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader user agent stylesheet header {
    display: block; }
  .AppHeader .elementor-kit-333 {
    --e-global-color-primary: #030533;
    --e-global-color-secondary: #0b0f69;
    --e-global-color-text: #000000;
    --e-global-color-accent: #000000;
    --e-global-color-dc89884: #262650;
    --e-global-color-67353d6e: #5a679e;
    --e-global-color-2dd1a94b: #383b4a;
    --e-global-color-1f898ed4: #5e5d69;
    --e-global-color-7769de19: #8c92a3;
    --e-global-color-140f09d0: #b0bcc2;
    --e-global-color-3856a817: #ced9d7;
    --e-global-color-1b1ac2b2: #e6e7e8;
    --e-global-color-56774a39: #f2f2f2;
    --e-global-color-11e70e0: #000000;
    --e-global-color-76c0f162: #ffffff;
    --e-global-typography-primary-font-family: 'Inter';
    --e-global-typography-primary-font-size: 40px;
    --e-global-typography-primary-font-weight: 800;
    --e-global-typography-primary-line-height: 48.41px;
    --e-global-typography-secondary-font-family: 'Inter';
    --e-global-typography-secondary-font-size: 30px;
    --e-global-typography-secondary-font-weight: 700;
    --e-global-typography-secondary-line-height: 36.31px;
    --e-global-typography-text-font-family: 'Inter';
    --e-global-typography-text-font-size: 16px;
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-text-line-height: 26px;
    --e-global-typography-accent-font-family: 'Inter';
    --e-global-typography-accent-font-size: 18px;
    --e-global-typography-accent-font-weight: 500;
    --e-global-typography-accent-line-height: 28.8px;
    --e-global-typography-9a68d50-font-family: 'Inter';
    --e-global-typography-9a68d50-font-size: 25px;
    --e-global-typography-9a68d50-font-weight: 500;
    --e-global-typography-9a68d50-line-height: 36.31px;
    --e-global-typography-b5d6e11-font-family: 'Inter';
    --e-global-typography-b5d6e11-font-size: 18px;
    --e-global-typography-b5d6e11-font-weight: 700;
    --e-global-typography-b5d6e11-line-height: 26px;
    --e-global-typography-1bf6aa4-font-family: 'Inter';
    --e-global-typography-1bf6aa4-font-size: 30px;
    --e-global-typography-1bf6aa4-font-weight: 800;
    --e-global-typography-1bf6aa4-line-height: 36.31px;
    --e-global-typography-a1b450f-font-family: 'Inter';
    --e-global-typography-a1b450f-font-size: 25px;
    --e-global-typography-a1b450f-font-weight: 700;
    --e-global-typography-a1b450f-line-height: 30.26px;
    --e-global-typography-fd0ec12-font-size: 15px;
    --e-global-typography-fd0ec12-font-weight: 400;
    --e-global-typography-fd0ec12-line-height: 25px; }
  .AppHeader body {
    font-family: PT Sans;
    font-size: 18px;
    color: #333333; }
  .AppHeader body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
      135deg,
      rgba(6, 147, 227, 1) 0%,
      rgb(155, 81, 224) 100%
    );
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
      135deg,
      rgb(122, 220, 180) 0%,
      rgb(0, 208, 130) 100%
    );
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
      135deg,
      rgba(252, 185, 0, 1) 0%,
      rgba(255, 105, 0, 1) 100%
    );
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
      135deg,
      rgba(255, 105, 0, 1) 0%,
      rgb(207, 46, 46) 100%
    );
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
      135deg,
      rgb(238, 238, 238) 0%,
      rgb(169, 184, 195) 100%
    );
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
      135deg,
      rgb(74, 234, 220) 0%,
      rgb(151, 120, 209) 20%,
      rgb(207, 42, 186) 40%,
      rgb(238, 44, 130) 60%,
      rgb(251, 105, 98) 80%,
      rgb(254, 248, 76) 100%
    );
    --wp--preset--gradient--blush-light-purple: linear-gradient(
      135deg,
      rgb(255, 206, 236) 0%,
      rgb(152, 150, 240) 100%
    );
    --wp--preset--gradient--blush-bordeaux: linear-gradient(
      135deg,
      rgb(254, 205, 165) 0%,
      rgb(254, 45, 45) 50%,
      rgb(107, 0, 62) 100%
    );
    --wp--preset--gradient--luminous-dusk: linear-gradient(
      135deg,
      rgb(255, 203, 112) 0%,
      rgb(199, 81, 192) 50%,
      rgb(65, 88, 208) 100%
    );
    --wp--preset--gradient--pale-ocean: linear-gradient(
      135deg,
      rgb(255, 245, 203) 0%,
      rgb(182, 227, 212) 50%,
      rgb(51, 167, 181) 100%
    );
    --wp--preset--gradient--electric-grass: linear-gradient(
      135deg,
      rgb(202, 248, 128) 0%,
      rgb(113, 206, 126) 100%
    );
    --wp--preset--gradient--midnight: linear-gradient(
      135deg,
      rgb(2, 3, 129) 0%,
      rgb(40, 116, 252) 100%
    );
    --wp--preset--duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
    --wp--preset--duotone--grayscale: url(#wp-duotone-grayscale);
    --wp--preset--duotone--purple-yellow: url(#wp-duotone-purple-yellow);
    --wp--preset--duotone--blue-red: url(#wp-duotone-blue-red);
    --wp--preset--duotone--midnight: url(#wp-duotone-midnight);
    --wp--preset--duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
    --wp--preset--duotone--purple-green: url(#wp-duotone-purple-green);
    --wp--preset--duotone--blue-orange: url(#wp-duotone-blue-orange);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
      6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1); }
  .AppHeader body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.8;
    color: #4a4a4a;
    overflow-wrap: break-word;
    word-wrap: break-word; }
  .AppHeader :root {
    --cmplz_banner_width: 526px;
    --cmplz_banner_background_color: #ffffff;
    --cmplz_banner_border_color: #f2f2f2;
    --cmplz_banner_border_width: 0px 0px 0px 0px;
    --cmplz_banner_border_radius: 12px 12px 12px 12px;
    --cmplz_banner_margin: 10px;
    --cmplz_categories-height: 163px;
    --cmplz_title_font_size: 15px;
    --cmplz_text_line_height: calc(var(--cmplz_text_font_size) * 1.5);
    --cmplz_text_color: #222222;
    --cmplz_hyperlink_color: #1e73be;
    --cmplz_text_font_size: 12px;
    --cmplz_link_font_size: 12px;
    --cmplz_category_body_font_size: 12px;
    --cmplz_button_accept_background_color: #0b0f69;
    --cmplz_button_accept_border_color: #0b0f69;
    --cmplz_button_accept_text_color: #ffffff;
    --cmplz_button_deny_background_color: #f9f9f9;
    --cmplz_button_deny_border_color: #f2f2f2;
    --cmplz_button_deny_text_color: #222222;
    --cmplz_button_settings_background_color: #f9f9f9;
    --cmplz_button_settings_border_color: #f2f2f2;
    --cmplz_button_settings_text_color: #333333;
    --cmplz_button_border_radius: 6px 6px 6px 6px;
    --cmplz_button_font_size: 15px;
    --cmplz_category_header_always_active_color: green;
    --cmplz_category_header_title_font_size: 14px;
    --cmplz_category_header_active_font_size: 12px;
    --cmplz-manage-consent-height: 50px;
    --cmplz-manage-consent-offset: -35px;
    --cmplz_slider_active_color: #1e73be;
    --cmplz_slider_inactive_color: #f56e28;
    --cmplz_slider_bullet_color: #ffffff; }
  .AppHeader :root {
    --page-title-display: block; }
  .AppHeader :root {
    --plyr-control-icon-size: 15px;
    --plyr-range-track-height: 3px;
    --plyr-range-thumb-height: 10px; }
  .AppHeader :root {
    --drawer-width: 480px;
    --neg-drawer-width: calc(var(--drawer-width) * -1); }
  .AppHeader :root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px; }
  .AppHeader html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; }
  .AppHeader html {
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader .col:after,
  .AppHeader .clr:after,
  .AppHeader .group:after,
  .AppHeader dl:after,
  .AppHeader .left_float:after,
  .AppHeader .right_float:after {
    content: '';
    display: block;
    visibility: hidden;
    clear: both;
    zoom: 1;
    height: 0; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader ::selection {
    color: #fff;
    background: #333;
    text-shadow: none; }
  .AppHeader html,
  .AppHeader body,
  .AppHeader div,
  .AppHeader span,
  .AppHeader applet,
  .AppHeader object,
  .AppHeader iframe,
  .AppHeader h1,
  .AppHeader h2,
  .AppHeader h3,
  .AppHeader h4,
  .AppHeader h5,
  .AppHeader h6,
  .AppHeader p,
  .AppHeader blockquote,
  .AppHeader pre,
  .AppHeader a,
  .AppHeader abbr,
  .AppHeader acronym,
  .AppHeader address,
  .AppHeader big,
  .AppHeader cite,
  .AppHeader code,
  .AppHeader del,
  .AppHeader dfn,
  .AppHeader em,
  .AppHeader img,
  .AppHeader ins,
  .AppHeader kbd,
  .AppHeader q,
  .AppHeader s,
  .AppHeader samp,
  .AppHeader small,
  .AppHeader strike,
  .AppHeader strong,
  .AppHeader sub,
  .AppHeader sup,
  .AppHeader tt,
  .AppHeader var,
  .AppHeader b,
  .AppHeader u,
  .AppHeader i,
  .AppHeader center,
  .AppHeader dl,
  .AppHeader dt,
  .AppHeader dd,
  .AppHeader ol,
  .AppHeader ul,
  .AppHeader li,
  .AppHeader fieldset,
  .AppHeader form,
  .AppHeader label,
  .AppHeader legend,
  .AppHeader table,
  .AppHeader caption,
  .AppHeader tbody,
  .AppHeader tfoot,
  .AppHeader thead,
  .AppHeader tr,
  .AppHeader th,
  .AppHeader td,
  .AppHeader article,
  .AppHeader aside,
  .AppHeader canvas,
  .AppHeader details,
  .AppHeader embed,
  .AppHeader figure,
  .AppHeader figcaption,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader output,
  .AppHeader ruby,
  .AppHeader section,
  .AppHeader summary,
  .AppHeader time,
  .AppHeader mark,
  .AppHeader audio,
  .AppHeader video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader user agent stylesheet div {
    display: block; }
  .AppHeader #site-header-inner {
    position: relative;
    height: 100%; }
  .AppHeader .container {
    width: 1440px; }
  .AppHeader .container {
    width: 1200px;
    max-width: 90%;
    margin: 0 auto; }
  .AppHeader .no-header-border #site-header {
    border-bottom: none; }
  .AppHeader #site-header {
    border-color: #333333; }
  .AppHeader #site-header {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    z-index: 100;
    height: 82px; }
  .AppHeader #site-header-inner {
    position: relative;
    height: 100%; }
  .AppHeader #site-logo {
    float: left;
    height: 100%;
    display: table; }
  .AppHeader #site-logo #site-logo-inner,
  .AppHeader .oceanwp-social-menu .social-menu-inner,
  .AppHeader #site-header.full_screen-header .menu-bar-inner,
  .AppHeader .after-header-content .after-header-content-inner {
    height: 81px; }
  .AppHeader #site-logo #site-logo-inner {
    display: table-cell;
    vertical-align: middle;
    height: 74px; }
  .AppHeader #site-logo #site-logo-inner a {
    background-color: rgba(0, 0, 0, 0) !important; }
  .AppHeader #site-logo #site-logo-inner a img,
  .AppHeader #site-header.center-header #site-navigation-wrap .middle-site-logo a img {
    max-width: 275px; }
  .AppHeader article,
  .AppHeader aside,
  .AppHeader details,
  .AppHeader figcaption,
  .AppHeader figure,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader main,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader section,
  .AppHeader summary {
    display: block; }
  .AppHeader article,
  .AppHeader aside,
  .AppHeader details,
  .AppHeader figcaption,
  .AppHeader figure,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader main,
  .AppHeader nav,
  .AppHeader section {
    display: block; }
  .AppHeader article,
  .AppHeader aside,
  .AppHeader details,
  .AppHeader figcaption,
  .AppHeader figure,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader section {
    display: block; }
  .AppHeader ﻿html,
  .AppHeader body,
  .AppHeader div,
  .AppHeader span,
  .AppHeader applet,
  .AppHeader object,
  .AppHeader iframe,
  .AppHeader h1,
  .AppHeader h2,
  .AppHeader h3,
  .AppHeader h4,
  .AppHeader h5,
  .AppHeader h6,
  .AppHeader p,
  .AppHeader blockquote,
  .AppHeader pre,
  .AppHeader a,
  .AppHeader abbr,
  .AppHeader acronym,
  .AppHeader address,
  .AppHeader big,
  .AppHeader cite,
  .AppHeader code,
  .AppHeader del,
  .AppHeader dfn,
  .AppHeader em,
  .AppHeader img,
  .AppHeader ins,
  .AppHeader kbd,
  .AppHeader q,
  .AppHeader s,
  .AppHeader samp,
  .AppHeader small,
  .AppHeader strike,
  .AppHeader strong,
  .AppHeader sub,
  .AppHeader sup,
  .AppHeader tt,
  .AppHeader var,
  .AppHeader b,
  .AppHeader u,
  .AppHeader i,
  .AppHeader center,
  .AppHeader dl,
  .AppHeader dt,
  .AppHeader dd,
  .AppHeader ol,
  .AppHeader ul,
  .AppHeader li,
  .AppHeader fieldset,
  .AppHeader form,
  .AppHeader label,
  .AppHeader legend,
  .AppHeader table,
  .AppHeader caption,
  .AppHeader tbody,
  .AppHeader tfoot,
  .AppHeader thead,
  .AppHeader tr,
  .AppHeader th,
  .AppHeader td,
  .AppHeader article,
  .AppHeader aside,
  .AppHeader canvas,
  .AppHeader details,
  .AppHeader embed,
  .AppHeader figure,
  .AppHeader figcaption,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader output,
  .AppHeader ruby,
  .AppHeader section,
  .AppHeader summary,
  .AppHeader time,
  .AppHeader mark,
  .AppHeader audio,
  .AppHeader video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader user agent stylesheet nav {
    display: block; }
  .AppHeader #site-logo #site-logo-inner a img {
    width: auto;
    vertical-align: middle;
    transition: all 0.3s ease-in-out; }
  .AppHeader #site-navigation-wrap {
    float: right;
    position: relative;
    right: -15px; }
  .AppHeader .col:after,
  .AppHeader .clr:after,
  .AppHeader .group:after,
  .AppHeader dl:after,
  .AppHeader .left_float:after,
  .AppHeader .right_float:after {
    content: '';
    display: block;
    visibility: hidden;
    clear: both;
    zoom: 1;
    height: 0; }
  .AppHeader #site-navigation-wrap .dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 0; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li {
    float: left;
    position: relative; }
  .AppHeader .sf-menu li.menu-item {
    position: relative;
    white-space: nowrap;
    white-space: normal; }
  .AppHeader .sf-menu > li {
    float: left; }
  .AppHeader .dropdown-menu,
  .AppHeader .dropdown-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader .sf-menu,
  .AppHeader .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.full_screen-header .fs-dropdown-menu > li > a,
  .AppHeader #site-header.top-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.center-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.medium-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase; }
  .AppHeader .dropdown-menu,
  .AppHeader .dropdown-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader .sf-menu,
  .AppHeader .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader ﻿html,
  .AppHeader body,
  .AppHeader div,
  .AppHeader span,
  .AppHeader applet,
  .AppHeader object,
  .AppHeader iframe,
  .AppHeader h1,
  .AppHeader h2,
  .AppHeader h3,
  .AppHeader h4,
  .AppHeader h5,
  .AppHeader h6,
  .AppHeader p,
  .AppHeader blockquote,
  .AppHeader pre,
  .AppHeader a,
  .AppHeader abbr,
  .AppHeader acronym,
  .AppHeader address,
  .AppHeader big,
  .AppHeader cite,
  .AppHeader code,
  .AppHeader del,
  .AppHeader dfn,
  .AppHeader em,
  .AppHeader img,
  .AppHeader ins,
  .AppHeader kbd,
  .AppHeader q,
  .AppHeader s,
  .AppHeader samp,
  .AppHeader small,
  .AppHeader strike,
  .AppHeader strong,
  .AppHeader sub,
  .AppHeader sup,
  .AppHeader tt,
  .AppHeader var,
  .AppHeader b,
  .AppHeader u,
  .AppHeader i,
  .AppHeader center,
  .AppHeader dl,
  .AppHeader dt,
  .AppHeader dd,
  .AppHeader ol,
  .AppHeader ul,
  .AppHeader li,
  .AppHeader fieldset,
  .AppHeader form,
  .AppHeader label,
  .AppHeader legend,
  .AppHeader table,
  .AppHeader caption,
  .AppHeader tbody,
  .AppHeader tfoot,
  .AppHeader thead,
  .AppHeader tr,
  .AppHeader th,
  .AppHeader td,
  .AppHeader article,
  .AppHeader aside,
  .AppHeader canvas,
  .AppHeader details,
  .AppHeader embed,
  .AppHeader figure,
  .AppHeader figcaption,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader output,
  .AppHeader ruby,
  .AppHeader section,
  .AppHeader summary,
  .AppHeader time,
  .AppHeader mark,
  .AppHeader audio,
  .AppHeader video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.full_screen-header .fs-dropdown-menu > li > a,
  .AppHeader #site-header.top-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.center-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.medium-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader #searchform-header-replace-close {
    color: #000000; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader .mobile-menu-close,
  .AppHeader .after-header-content-inner > a {
    line-height: 81px; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a {
    display: block;
    font-size: 13px;
    line-height: 81px;
    color: #000000;
    padding: 0 15px;
    letter-spacing: 0.6px;
    text-align: left; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li {
    float: left;
    position: relative; }
  .AppHeader .sf-menu li.menu-item {
    position: relative;
    white-space: nowrap;
    white-space: normal; }
  .AppHeader .sf-menu > li {
    float: left; }
  .AppHeader .dropdown-menu,
  .AppHeader .dropdown-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader .sf-menu,
  .AppHeader .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader ﻿html,
  .AppHeader body,
  .AppHeader div,
  .AppHeader span,
  .AppHeader applet,
  .AppHeader object,
  .AppHeader iframe,
  .AppHeader h1,
  .AppHeader h2,
  .AppHeader h3,
  .AppHeader h4,
  .AppHeader h5,
  .AppHeader h6,
  .AppHeader p,
  .AppHeader blockquote,
  .AppHeader pre,
  .AppHeader a,
  .AppHeader abbr,
  .AppHeader acronym,
  .AppHeader address,
  .AppHeader big,
  .AppHeader cite,
  .AppHeader code,
  .AppHeader del,
  .AppHeader dfn,
  .AppHeader em,
  .AppHeader img,
  .AppHeader ins,
  .AppHeader kbd,
  .AppHeader q,
  .AppHeader s,
  .AppHeader samp,
  .AppHeader small,
  .AppHeader strike,
  .AppHeader strong,
  .AppHeader sub,
  .AppHeader sup,
  .AppHeader tt,
  .AppHeader var,
  .AppHeader b,
  .AppHeader u,
  .AppHeader i,
  .AppHeader center,
  .AppHeader dl,
  .AppHeader dt,
  .AppHeader dd,
  .AppHeader ol,
  .AppHeader ul,
  .AppHeader li,
  .AppHeader fieldset,
  .AppHeader form,
  .AppHeader label,
  .AppHeader legend,
  .AppHeader table,
  .AppHeader caption,
  .AppHeader tbody,
  .AppHeader tfoot,
  .AppHeader thead,
  .AppHeader tr,
  .AppHeader th,
  .AppHeader td,
  .AppHeader article,
  .AppHeader aside,
  .AppHeader canvas,
  .AppHeader details,
  .AppHeader embed,
  .AppHeader figure,
  .AppHeader figcaption,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader output,
  .AppHeader ruby,
  .AppHeader section,
  .AppHeader summary,
  .AppHeader time,
  .AppHeader mark,
  .AppHeader audio,
  .AppHeader video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader user agent stylesheet li {
    display: list-item;
    text-align: -webkit-match-parent; }
  .AppHeader .dropdown-menu,
  .AppHeader .dropdown-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader .sf-menu,
  .AppHeader .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader ﻿html,
  .AppHeader body,
  .AppHeader div,
  .AppHeader span,
  .AppHeader applet,
  .AppHeader object,
  .AppHeader iframe,
  .AppHeader h1,
  .AppHeader h2,
  .AppHeader h3,
  .AppHeader h4,
  .AppHeader h5,
  .AppHeader h6,
  .AppHeader p,
  .AppHeader blockquote,
  .AppHeader pre,
  .AppHeader a,
  .AppHeader abbr,
  .AppHeader acronym,
  .AppHeader address,
  .AppHeader big,
  .AppHeader cite,
  .AppHeader code,
  .AppHeader del,
  .AppHeader dfn,
  .AppHeader em,
  .AppHeader img,
  .AppHeader ins,
  .AppHeader kbd,
  .AppHeader q,
  .AppHeader s,
  .AppHeader samp,
  .AppHeader small,
  .AppHeader strike,
  .AppHeader strong,
  .AppHeader sub,
  .AppHeader sup,
  .AppHeader tt,
  .AppHeader var,
  .AppHeader b,
  .AppHeader u,
  .AppHeader i,
  .AppHeader center,
  .AppHeader dl,
  .AppHeader dt,
  .AppHeader dd,
  .AppHeader ol,
  .AppHeader ul,
  .AppHeader li,
  .AppHeader fieldset,
  .AppHeader form,
  .AppHeader label,
  .AppHeader legend,
  .AppHeader table,
  .AppHeader caption,
  .AppHeader tbody,
  .AppHeader tfoot,
  .AppHeader thead,
  .AppHeader tr,
  .AppHeader th,
  .AppHeader td,
  .AppHeader article,
  .AppHeader aside,
  .AppHeader canvas,
  .AppHeader details,
  .AppHeader embed,
  .AppHeader figure,
  .AppHeader figcaption,
  .AppHeader footer,
  .AppHeader header,
  .AppHeader hgroup,
  .AppHeader menu,
  .AppHeader nav,
  .AppHeader output,
  .AppHeader ruby,
  .AppHeader section,
  .AppHeader summary,
  .AppHeader time,
  .AppHeader mark,
  .AppHeader audio,
  .AppHeader video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.full_screen-header .fs-dropdown-menu > li > a,
  .AppHeader #site-header.top-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.center-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.medium-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader #searchform-header-replace-close {
    color: #000000; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader .mobile-menu-close,
  .AppHeader .after-header-content-inner > a {
    line-height: 81px; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a {
    display: block;
    font-size: 13px;
    line-height: 74px;
    color: #555;
    padding: 0 15px;
    letter-spacing: 0.6px; }
  .AppHeader .elementor-kit-333 a {
    color: var(--e-global-color-11e70e0);
    font-family: 'Inter', Sans-serif;
    font-size: 16px;
    font-weight: 400; }
  .AppHeader a {
    color: #0057af; }
  .AppHeader a,
  .AppHeader a:hover,
  .AppHeader a:focus {
    text-decoration: none;
    transition: all 0.3s ease; }
  .AppHeader a {
    color: #333; }
  .AppHeader user agent stylesheet a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer; }
  .AppHeader .sf-menu li.menu-item {
    position: relative;
    white-space: nowrap;
    white-space: normal; }
  .AppHeader user agent stylesheet li {
    text-align: -webkit-match-parent; }
  .AppHeader #site-navigation-wrap .dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 0; }
  .AppHeader user agent stylesheet ul {
    list-style-type: disc; }
  .AppHeader .elementor-kit-333 {
    --e-global-color-primary: #030533;
    --e-global-color-secondary: #0b0f69;
    --e-global-color-text: #000000;
    --e-global-color-accent: #000000;
    --e-global-color-dc89884: #262650;
    --e-global-color-67353d6e: #5a679e;
    --e-global-color-2dd1a94b: #383b4a;
    --e-global-color-1f898ed4: #5e5d69;
    --e-global-color-7769de19: #8c92a3;
    --e-global-color-140f09d0: #b0bcc2;
    --e-global-color-3856a817: #ced9d7;
    --e-global-color-1b1ac2b2: #e6e7e8;
    --e-global-color-56774a39: #f2f2f2;
    --e-global-color-11e70e0: #000000;
    --e-global-color-76c0f162: #ffffff;
    --e-global-typography-primary-font-family: 'Inter';
    --e-global-typography-primary-font-size: 40px;
    --e-global-typography-primary-font-weight: 800;
    --e-global-typography-primary-line-height: 48.41px;
    --e-global-typography-secondary-font-family: 'Inter';
    --e-global-typography-secondary-font-size: 30px;
    --e-global-typography-secondary-font-weight: 700;
    --e-global-typography-secondary-line-height: 36.31px;
    --e-global-typography-text-font-family: 'Inter';
    --e-global-typography-text-font-size: 16px;
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-text-line-height: 26px;
    --e-global-typography-accent-font-family: 'Inter';
    --e-global-typography-accent-font-size: 18px;
    --e-global-typography-accent-font-weight: 500;
    --e-global-typography-accent-line-height: 28.8px;
    --e-global-typography-9a68d50-font-family: 'Inter';
    --e-global-typography-9a68d50-font-size: 25px;
    --e-global-typography-9a68d50-font-weight: 500;
    --e-global-typography-9a68d50-line-height: 36.31px;
    --e-global-typography-b5d6e11-font-family: 'Inter';
    --e-global-typography-b5d6e11-font-size: 18px;
    --e-global-typography-b5d6e11-font-weight: 700;
    --e-global-typography-b5d6e11-line-height: 26px;
    --e-global-typography-1bf6aa4-font-family: 'Inter';
    --e-global-typography-1bf6aa4-font-size: 30px;
    --e-global-typography-1bf6aa4-font-weight: 800;
    --e-global-typography-1bf6aa4-line-height: 36.31px;
    --e-global-typography-a1b450f-font-family: 'Inter';
    --e-global-typography-a1b450f-font-size: 25px;
    --e-global-typography-a1b450f-font-weight: 700;
    --e-global-typography-a1b450f-line-height: 30.26px;
    --e-global-typography-fd0ec12-font-size: 15px;
    --e-global-typography-fd0ec12-font-weight: 400;
    --e-global-typography-fd0ec12-line-height: 25px; }
  .AppHeader body {
    font-family: PT Sans;
    font-size: 18px;
    color: #333333; }
  .AppHeader body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
      135deg,
      rgba(6, 147, 227, 1) 0%,
      rgb(155, 81, 224) 100%
    );
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
      135deg,
      rgb(122, 220, 180) 0%,
      rgb(0, 208, 130) 100%
    );
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
      135deg,
      rgba(252, 185, 0, 1) 0%,
      rgba(255, 105, 0, 1) 100%
    );
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
      135deg,
      rgba(255, 105, 0, 1) 0%,
      rgb(207, 46, 46) 100%
    );
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
      135deg,
      rgb(238, 238, 238) 0%,
      rgb(169, 184, 195) 100%
    );
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
      135deg,
      rgb(74, 234, 220) 0%,
      rgb(151, 120, 209) 20%,
      rgb(207, 42, 186) 40%,
      rgb(238, 44, 130) 60%,
      rgb(251, 105, 98) 80%,
      rgb(254, 248, 76) 100%
    );
    --wp--preset--gradient--blush-light-purple: linear-gradient(
      135deg,
      rgb(255, 206, 236) 0%,
      rgb(152, 150, 240) 100%
    );
    --wp--preset--gradient--blush-bordeaux: linear-gradient(
      135deg,
      rgb(254, 205, 165) 0%,
      rgb(254, 45, 45) 50%,
      rgb(107, 0, 62) 100%
    );
    --wp--preset--gradient--luminous-dusk: linear-gradient(
      135deg,
      rgb(255, 203, 112) 0%,
      rgb(199, 81, 192) 50%,
      rgb(65, 88, 208) 100%
    );
    --wp--preset--gradient--pale-ocean: linear-gradient(
      135deg,
      rgb(255, 245, 203) 0%,
      rgb(182, 227, 212) 50%,
      rgb(51, 167, 181) 100%
    );
    --wp--preset--gradient--electric-grass: linear-gradient(
      135deg,
      rgb(202, 248, 128) 0%,
      rgb(113, 206, 126) 100%
    );
    --wp--preset--gradient--midnight: linear-gradient(
      135deg,
      rgb(2, 3, 129) 0%,
      rgb(40, 116, 252) 100%
    );
    --wp--preset--duotone--dark-grayscale: url(#wp-duotone-dark-grayscale);
    --wp--preset--duotone--grayscale: url(#wp-duotone-grayscale);
    --wp--preset--duotone--purple-yellow: url(#wp-duotone-purple-yellow);
    --wp--preset--duotone--blue-red: url(#wp-duotone-blue-red);
    --wp--preset--duotone--midnight: url(#wp-duotone-midnight);
    --wp--preset--duotone--magenta-yellow: url(#wp-duotone-magenta-yellow);
    --wp--preset--duotone--purple-green: url(#wp-duotone-purple-green);
    --wp--preset--duotone--blue-orange: url(#wp-duotone-blue-orange);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
      6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1); }
  .AppHeader body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.8;
    color: #4a4a4a;
    overflow-wrap: break-word;
    /* word-wrap: break-word; */ }
  .AppHeader :root {
    --cmplz_banner_width: 526px;
    --cmplz_banner_background_color: #ffffff;
    --cmplz_banner_border_color: #f2f2f2;
    --cmplz_banner_border_width: 0px 0px 0px 0px;
    --cmplz_banner_border_radius: 12px 12px 12px 12px;
    --cmplz_banner_margin: 10px;
    --cmplz_categories-height: 163px;
    --cmplz_title_font_size: 15px;
    --cmplz_text_line_height: calc(var(--cmplz_text_font_size) * 1.5);
    --cmplz_text_color: #222222;
    --cmplz_hyperlink_color: #1e73be;
    --cmplz_text_font_size: 12px;
    --cmplz_link_font_size: 12px;
    --cmplz_category_body_font_size: 12px;
    --cmplz_button_accept_background_color: #0b0f69;
    --cmplz_button_accept_border_color: #0b0f69;
    --cmplz_button_accept_text_color: #ffffff;
    --cmplz_button_deny_background_color: #f9f9f9;
    --cmplz_button_deny_border_color: #f2f2f2;
    --cmplz_button_deny_text_color: #222222;
    --cmplz_button_settings_background_color: #f9f9f9;
    --cmplz_button_settings_border_color: #f2f2f2;
    --cmplz_button_settings_text_color: #333333;
    --cmplz_button_border_radius: 6px 6px 6px 6px;
    --cmplz_button_font_size: 15px;
    --cmplz_category_header_always_active_color: green;
    --cmplz_category_header_title_font_size: 14px;
    --cmplz_category_header_active_font_size: 12px;
    --cmplz-manage-consent-height: 50px;
    --cmplz-manage-consent-offset: -35px;
    --cmplz_slider_active_color: #1e73be;
    --cmplz_slider_inactive_color: #f56e28;
    --cmplz_slider_bullet_color: #ffffff; }
  .AppHeader :root {
    --plyr-control-icon-size: 15px;
    --plyr-range-track-height: 3px;
    --plyr-range-thumb-height: 10px; }
  .AppHeader :root {
    --drawer-width: 480px;
    --neg-drawer-width: calc(var(--drawer-width) * -1); }
  .AppHeader :root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px; }
  .AppHeader html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%; }
  .AppHeader html {
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader *,
  .AppHeader *:before,
  .AppHeader *:after {
    box-sizing: border-box; }
  .AppHeader ::selection {
    color: #fff;
    background: #333;
    text-shadow: none; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader #searchform-header-replace-close {
    color: #000000; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader #searchform-header-replace-close {
    color: #000000; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader .mobile-menu-close,
  .AppHeader .after-header-content-inner > a {
    line-height: 81px; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a {
    display: block;
    font-size: 13px;
    line-height: 74px;
    color: #555;
    padding: 0 15px;
    letter-spacing: 0.6px; }
  .AppHeader .sf-menu a.menu-link {
    display: block;
    position: relative;
    zoom: 1; }
  .AppHeader .menu-link span {
    font-size: 14px; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a .nav-arrow {
    padding-left: 6px;
    line-height: 1; }
  .AppHeader .dropdown-menu li a.menu-link i {
    padding-right: 5px; }
  .AppHeader .fa,
  .AppHeader .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900; }
  .AppHeader .fa,
  .AppHeader .fab,
  .AppHeader .fad,
  .AppHeader .fal,
  .AppHeader .far,
  .AppHeader .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1; }
  .AppHeader .fa-angle-down:before {
    content: '\f107'; }
  .AppHeader .sf-menu ul.sub-menu {
    position: absolute;
    visibility: hidden;
    top: 100%;
    left: 0;
    z-index: 9999; }
  .AppHeader .dropdown-menu .sub-menu,
  .AppHeader #searchform-dropdown,
  .AppHeader .current-shop-items-dropdown {
    background-color: #212222; }
  .AppHeader .dropdown-menu .sub-menu {
    border-color: #6ec1e4; }
  .AppHeader .dropdown-menu .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-top: 3px solid #13aff0;
    min-width: 180px;
    line-height: 1;
    text-align: left;
    z-index: 999;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
  .AppHeader .dropdown-menu li .sub-menu li.menu-item {
    display: block;
    float: none; }
  .AppHeader .dropdown-menu ul li.menu-item,
  .AppHeader .navigation > ul > li > ul.megamenu.sub-menu > li,
  .AppHeader .navigation .megamenu li ul.sub-menu {
    border-color: #000000; }
  .AppHeader .dropdown-menu ul li.menu-item {
    display: block;
    border-bottom: 1px solid #000000; }
  .AppHeader .sf-menu li.menu-item {
    position: relative;
    white-space: nowrap;
    white-space: normal; }
  .AppHeader .dropdown-menu ul li a.menu-link,
  .AppHeader #site-header.full_screen-header .fs-dropdown-menu ul.sub-menu li a {
    font-size: 14px;
    line-height: 1.4; }
  .AppHeader .dropdown-menu ul li a.menu-link {
    color: #a9a9a9; }
  .AppHeader .dropdown-menu ul li a.menu-link {
    display: block;
    position: relative;
    float: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0.6px;
    padding: 12px 15px;
    text-transform: capitalize; }
  .AppHeader .sf-menu a.menu-link {
    display: block;
    position: relative;
    zoom: 1; }
  .AppHeader .dropdown-menu,
  .AppHeader .dropdown-menu * {
    margin: 0;
    padding: 0;
    list-style: none; }
  .AppHeader .dropdown-menu .sub-menu,
  .AppHeader #searchform-dropdown,
  .AppHeader .current-shop-items-dropdown {
    background-color: #212222; }
  .AppHeader #searchform-dropdown {
    border-color: #6ec1e4; }
  .AppHeader #searchform-dropdown,
  .AppHeader #icon-searchform-dropdown {
    position: absolute;
    right: 0;
    background-color: #fff;
    border-top: 3px solid #13aff0;
    top: 100%;
    padding: 15px;
    width: 260px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    z-index: 10000; }
  .AppHeader #searchform-dropdown input {
    border-color: #000000; }
  .AppHeader #searchform-dropdown input {
    color: #ffffff; }
  .AppHeader #searchform-dropdown input,
  .AppHeader #icon-searchform-dropdown input {
    display: block;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    margin: 0; }
  .AppHeader .col:after,
  .AppHeader .clr:after,
  .AppHeader .group:after,
  .AppHeader dl:after,
  .AppHeader .left_float:after,
  .AppHeader .right_float:after {
    content: '';
    display: block;
    visibility: hidden;
    clear: both;
    zoom: 1;
    height: 0; }
  .AppHeader .oceanwp-mobile-menu-icon.mobile-right {
    float: right; }
  .AppHeader .oceanwp-mobile-menu-icon {
    display: none;
    position: relative; }
  .AppHeader #sidr,
  .AppHeader #mobile-dropdown {
    background-color: #212222; }
  .AppHeader #mobile-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
  .AppHeader .sf-menu li:hover,
  .AppHeader .sf-menu li.sfHover {
    transition: none; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a:hover,
  .AppHeader .oceanwp-mobile-menu-icon a:hover,
  .AppHeader #searchform-header-replace-close:hover {
    color: #6ec1e4; }
  .AppHeader .woocommerce-MyAccount-navigation ul li a:before,
  .AppHeader .woocommerce-checkout .woocommerce-info a,
  .AppHeader .woocommerce-checkout
#payment
ul.payment_methods
.wc_payment_method
> input[type='radio']:first-child:checked
+ label:before,
  .AppHeader .woocommerce-checkout #payment .payment_method_paypal .about_paypal,
  .AppHeader .woocommerce ul.products li.product li.category a:hover,
  .AppHeader .woocommerce ul.products li.product .button:hover,
  .AppHeader .woocommerce ul.products li.product .product-inner .added_to_cart:hover,
  .AppHeader .product_meta .posted_in a:hover,
  .AppHeader .product_meta .tagged_as a:hover,
  .AppHeader .woocommerce div.product .woocommerce-tabs ul.tabs li a:hover,
  .AppHeader .woocommerce div.product .woocommerce-tabs ul.tabs li.active a,
  .AppHeader .woocommerce .oceanwp-grid-list a.active,
  .AppHeader .woocommerce .oceanwp-grid-list a:hover,
  .AppHeader .woocommerce .oceanwp-off-canvas-filter:hover,
  .AppHeader .widget_shopping_cart ul.cart_list li .owp-grid-wrap .owp-grid a.remove:hover,
  .AppHeader .widget_product_categories li a:hover ~ .count,
  .AppHeader .widget_layered_nav li a:hover ~ .count,
  .AppHeader .woocommerce ul.products li.product:not(.product-category) .woo-entry-buttons li a:hover,
  .AppHeader a:hover,
  .AppHeader a.light:hover,
  .AppHeader .theme-heading .text::before,
  .AppHeader .theme-heading .text::after,
  .AppHeader #top-bar-content > a:hover,
  .AppHeader #top-bar-social li.oceanwp-email a:hover,
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a:hover,
  .AppHeader #site-header.medium-header #medium-searchform button:hover,
  .AppHeader .oceanwp-mobile-menu-icon a:hover,
  .AppHeader .blog-entry.post .blog-entry-header .entry-title a:hover,
  .AppHeader .blog-entry.post .blog-entry-readmore a:hover,
  .AppHeader .blog-entry.thumbnail-entry .blog-entry-category a,
  .AppHeader ul.meta li a:hover,
  .AppHeader .dropcap,
  .AppHeader .single nav.post-navigation .nav-links .title,
  .AppHeader body .related-post-title a:hover,
  .AppHeader body #wp-calendar caption,
  .AppHeader body .contact-info-widget.default i,
  .AppHeader body .contact-info-widget.big-icons i,
  .AppHeader body .custom-links-widget .oceanwp-custom-links li a:hover,
  .AppHeader body .custom-links-widget .oceanwp-custom-links li a:hover:before,
  .AppHeader body .posts-thumbnails-widget li a:hover,
  .AppHeader body .social-widget li.oceanwp-email a:hover,
  .AppHeader .comment-author .comment-meta .comment-reply-link,
  .AppHeader #respond #cancel-comment-reply-link:hover,
  .AppHeader #footer-widgets .footer-box a:hover,
  .AppHeader #footer-bottom a:hover,
  .AppHeader #footer-bottom #footer-bottom-menu a:hover,
  .AppHeader .sidr a:hover,
  .AppHeader .sidr-class-dropdown-toggle:hover,
  .AppHeader .sidr-class-menu-item-has-children.active > a,
  .AppHeader .sidr-class-menu-item-has-children.active > a > .sidr-class-dropdown-toggle,
  .AppHeader input[type='checkbox']:checked:before {
    color: #6ec1e4; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a:hover {
    color: #13aff0; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.full_screen-header .fs-dropdown-menu > li > a,
  .AppHeader #site-header.top-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.center-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader #site-header.medium-header #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader #searchform-header-replace-close {
    color: #000000; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a,
  .AppHeader .oceanwp-mobile-menu-icon a,
  .AppHeader .mobile-menu-close,
  .AppHeader .after-header-content-inner > a {
    line-height: 81px; }
  .AppHeader #site-navigation-wrap .dropdown-menu > li > a {
    display: block;
    font-size: 13px;
    line-height: 74px;
    color: #555;
    padding: 0 15px;
    letter-spacing: 0.6px; }
  .AppHeader .elementor-kit-333 a:hover {
    color: var(--e-global-color-140f09d0); }
  .AppHeader .sf-menu a.menu-link {
    display: block;
    position: relative;
    zoom: 1; }
  .AppHeader a:hover {
    color: #a00000; }
  .AppHeader .elementor-kit-333 a {
    color: var(--e-global-color-11e70e0);
    font-family: 'Inter', Sans-serif;
    font-size: 16px;
    font-weight: 400; }
  .AppHeader a,
  .AppHeader a:hover,
  .AppHeader a:focus {
    text-decoration: none;
    transition: all 0.3s ease; }
  .AppHeader a:hover {
    color: #13aff0; }
  .AppHeader a:active,
  .AppHeader a:hover {
    outline: 0; }
  .AppHeader a:hover,
  .AppHeader a:active {
    outline: 0; }
  .AppHeader .sf-menu li:hover > ul,
  .AppHeader .sf-menu li.sfHover > ul {
    display: block; }
  .AppHeader .sf-menu ul.sub-menu {
    position: absolute;
    visibility: hidden;
    top: 100%;
    left: 0;
    z-index: 9999; }

